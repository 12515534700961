.related-initiatives__header {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.related-initiatives__more {
  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }
  @include media-breakpoint-up(md) {
    margin: auto 0 0 auto;
    width: auto;
  }
}

.related-initiatives {
  .glide__track {
    overflow: inherit;
  }
  .glide__slides {
  }
}
