.breadcrumb-list {
  --breadcrumb-spacing: 1ex;

  color: var(--bs-primary);
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  gap: var(--breadcrumb-spacing);
  background-color: var(--bs-gray-100);
  text-transform: none;
  padding: 1.5ex spacer(3) !important;
  border-radius: 60px;
  margin-right: 2px;
  width: auto;

  .theme-primary & {
    background-color: var(--bs-primary);
  }

  // @include media-breakpoint-down(lg) {
  //   padding-left: 15px;
  //   padding-right: 20px;
  // }

  @include media-breakpoint-down(md) {
    height: rem($button-size);
  }

  &__item {
    display: flex;

    &:not(:last-child)::after {
      content: '/';
      display: inline-block;
      font-size: 0.8em;
      margin-left: var(--breadcrumb-spacing);
      transform: translateY(5px);
    }

    @include media-breakpoint-down(lg) {
      // &:not(:nth-child(-n+1)),
      // &::after {
      //   display: none!important;
      // }
    }
  }
}

.breadcrumb {
  color: var(--bs-primary);
  text-decoration: none;
  font-weight: 600;
  // display: inline-flex;
  align-items: center;
  font-size: rem(14);
  display: flex;

  &__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 150px;

    @include media-breakpoint-down(sm) {
      max-width: 100px;
    }

    @include media-breakpoint-up(lg) {
      max-width: calc((100vw - 760px) / var(--breadcrumb-count));
    }

    @include media-breakpoint-up(xxl) {
      // 1400 = contianer size
      max-width: calc((1400px - 760px) / var(--breadcrumb-count));
    }
  }

  app-icon {
    margin-right: 10px;
    fill: currentColor;
    display: none;

    @include media-breakpoint-down(lg) {
      display: inline-block;
    }
  }

  &:hover {
    text-decoration: underline;
    color: darkenColor(var(--bs-primary), 0.2);
  }

  .is--current & {
    color: var(--bs-secondary);
    font-weight: 410;
    pointer-events: none;
  }
}
