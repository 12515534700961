.vote__header {
  background: var(--bs-primary);
  position: relative;
  border-radius: 2px;

  &:before {
    content: '';
    @include abs(99%, null, null, 0);
    @include size(0);

    border-top: 0px solid transparent;
    border-left: 24px solid var(--bs-primary);
    border-bottom: 30px solid transparent;
  }
}

.vote__valid {
  position: relative;
  border-radius: $border-radius;
  width: 100%;

  &:before {
    content: '';
    @include abs(99%, 0, null, null);
    @include size(0);

    border-top: 0px solid transparent;
    border-right: 24px solid var(--bs-secondary);
    border-bottom: 30px solid transparent;
  }
}

.vote__result__text {
  align-items: center;
}

.vote__result__text__col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
