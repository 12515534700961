// Custom Theming for Angular Material
@use '@angular/material' as mat;

@include mat.legacy-core();

// Project specific settings
$mat-primary-map: makeColorPalette(theme-color(primary));
$mat-accent-map: makeColorPalette(theme-color(secondary));
$mat-warn-map: makeColorPalette(theme-color(danger));

$mat-typography: mat.define-legacy-typography-config(
  $font-family: $font-family-sans-serif,
);

/*
  Material implementation
*/

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($mat-primary-map),
      accent: mat.define-palette($mat-accent-map),
      warn: mat.define-palette($mat-warn-map),
    ),
    typography: $mat-typography,
  )
);

@include mat.legacy-core-theme($custom-theme);
@include mat.all-legacy-component-themes($custom-theme);
