.mat-form-field {
  width: 100%;
}

.theme-light {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white;
  }
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: white;
  border: 1px solid var(--bs-gray-300);
  border-radius: 5px !important;
}

textarea.mat-input-element {
  line-height: 1.5;
}

.mat-checkbox {
  .mat-checkbox-label {
    white-space: normal;
  }

  .mat-checkbox-inner-container {
    margin-top: 0.3em;
  }
}

// weird height bugfix
.mat-form-field.mat-accent .mat-input-element[type='time'] {
  margin-bottom: -0.9625em;
}

.mat-form-field-wrapper:has(:focus-visible) .mat-form-field-flex {
  outline: 2px solid red;
}
