.label {
  font-size: var(--mat-label-font-size);
  color: var(--mat-label-color);
  display: block;
  // margin-bottom: 0.4em;
  font-size: var(--mat-label-font-size);
  color: #5f6060;
  font-size: 0.7em;
}

@keyframes animateErrorIn {
  from {
    transform: translateY(-30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.custom-field {
  position: relative;
  margin-top: calc(var(--mat-error-height) / 2);

  background: white;
  border-radius: 5px;
  border: 1px solid var(--bs-gray-300);
  padding: 15px 12px 8px;

  &-subLabel {
    margin-bottom: 0.5em;
  }

  &__field {
    margin-top: var(--mat-label-bottom-spacing);
    width: 100%;
  }

  &__error-wrapper {
    height: var(--mat-error-height);
    overflow: hidden;
    position: relative;
    top: calc(100% - var(--mat-error-height));
    margin-top: calc(var(--mat-error-height) / 2);

    .mat-error {
      font-size: 75%;
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      padding: 0 1em 0 0;
      font-weight: 400;

      transform: translateY(-50%);
      will-change: transform;
      animation: animateErrorIn 0.3s ease-out;
      animation-fill-mode: forwards;
    }
  }

  &.custom-field--invalid {
    .label {
      color: var(--mat-warn-color, #{map-get($mat-warn-map, 500)});
    }

    .mat-form-field-appearance-fill.mat-form-field-type-mat-input .mat-form-field-flex {
      border-color: var(--mat-warn-color, #{map-get($mat-warn-map, 500)});
    }
  }
}
