.story-card {
  @include size(100%);

  .block-link {
    @include size(100%);
    position: relative;
    overflow: hidden;
    display: block;
    transform-origin: center center;
    transition: transform 0.2s ease-in-out;
    border-radius: $border-radius-lg;

    &:focus-visible {
      transform: scale(1.02);
    }

    @media (hover: hover) {
      &:hover {
        // .initiative-card__figure img {
        transform: scale(1.02);
        // }
      }
    }
  }

  &__meta {
    display: flex;
    gap: spacer(2);
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  &__content {
    @include abs(0);
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.737) 0%, rgba(0, 0, 0, 0) 100%);

    &-spacing {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
    }

    &-titles {
      margin-top: auto;
      margin-bottom: 0;
      word-wrap: break-word;
    }

    &-title {
      font-weight: 700;
      line-height: 1.3;
    }

    &-subtitle {
      display: block;
      line-height: 1.2;
    }

    &-text {
      color: var(--bs-light);
    }
  }
}

// @media (min-width: 795px) {
//   .story-card__content-title {
//     @include font-size(1rem);
//   }
// }
