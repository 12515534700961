.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: #{$border-radius-lg};
  --mdc-dialog-container-elevation: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  --mdc-dialog-supporting-text-font: #{$font-family-base};
  --mdc-dialog-supporting-text-line-height: 1.5;

  .mdc-dialog__surface {
    padding: spacer(2);
  }
}

.cdk-overlay-dark-backdrop {
  background-color: alpha(var(--bs-dark), 0.3);
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}
