.header {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    &__inner {
      position: relative;
      z-index: 3;
    }
  }

  &__logo-title {
    max-width: 270px;
    width: 100%;
    margin-bottom: spacer(5);

    @include media-breakpoint-up(xl) {
      max-width: 320px;
    }

    @include media-breakpoint-up(md) {
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-down(sm) {
      max-width: 180px;
    }
  }

  &__content {
    z-index: 3;
    position: relative;
  }

  &__figure {
    --mobile-card-link-shift: 50px;
    z-index: 1;
    position: relative;

    @include media-breakpoint-up(xxxl) {
      margin-bottom: spacer(5);
    }

    @include media-breakpoint-down(md) {
      margin-bottom: calc(var(--mobile-card-link-shift) * 2);
    }

    &__inner {
      height: 100%;
      display: flex;

      @include media-breakpoint-down(md) {
        margin-right: 0;
      }

      &.has--overlay::before {
        content: '';
        @include abs(0, 0, 0, calc(var(--bs-gutter-x) * 0.5));
        z-index: 1;
        margin-right: var(--space-left);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.15%, rgba(0, 0, 0, 0) 62.15%);
        border-bottom-left-radius: $border-radius-lg;

        @include media-breakpoint-down(md) {
          border-radius: $border-radius-lg;
          right: calc(var(--bs-gutter-x) * 0.5);
          margin-right: 0;
        }
      }
    }

    img {
      border-top-left-radius: $border-radius-lg;
      border-bottom-left-radius: $border-radius-lg;
      min-height: 350px;
      //max-height: 75vmin;

      @include media-breakpoint-down(md) {
        border-radius: $border-radius-lg;
      }
    }
  }

  .wave-front {
    position: absolute;
    left: 0;
    right: 0;
    width: -webkit-fill-available;
    display: block;
    padding: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .wave-bottom {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: block;
    pointer-events: none;
    width: 100%;
    @include media-breakpoint-down(sm) {
      .bg-wave {
        height: 80px;
      }
    }
  }

  &--dark {
    background-color: var(--bs-primary);
    color: white;
    padding-bottom: calc(var(--section-spacing) * 2);

    .wave-front {
      bottom: calc(var(--section-spacing) * 1.5);
    }
  }

  .header-image-overlay {
    position: absolute;

    display: flex;
    gap: spacer(2);
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;

    left: calc(var(--bs-gutter-x) * 0.5);
    right: calc(var(--bs-gutter-x) * 1);
    bottom: calc(var(--section-spacing) * 0.7);
    margin-right: var(--space-left);
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      bottom: 0;
    }

    &__content {
      position: relative;
      padding: spacer(4) spacer(5) spacer(0) spacer(5);
      //z-index: 1;
      max-width: map-get($text-max-widths, xxs);

      @include media-breakpoint-down(md) {
        padding: spacer(2) spacer(3);
      }
    }

    //.card-link {
    //  position: relative;
    //  z-index: 1;
    //}
  }
}

.card-link {
  background: white;
  border-radius: 60px 0px 0px 60px;
  display: inline-flex;
  align-items: center;
  padding: 10px 30px 10px 30px;
  gap: 20px;
  text-decoration: none;
  margin: 0 0 20px auto;

  @include media-breakpoint-down(md) {
    border-radius: 60px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 10px 10px 30px;
    margin-bottom: calc(var(--mobile-card-link-shift) * -1);
    box-shadow: $box-shadow;
  }

  &__label {
    font-size: rem(48px);
    font-weight: 500;
    color: var(--bs-primary);
    font-family: $font-family-serif;
    white-space: nowrap;
    text-decoration: none;

    @include media-breakpoint-down(xl) {
      font-size: 2rem;
    }
  }
}
