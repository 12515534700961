.content-block-cta {
  background-color: var(--bs-secondary);
  display: flex;
  text-align: center;
}

//.cta-bgwave {
//  .bg-wave {
//    width: 100%;

//    @include media-breakpoint-down(md) {
//      height: 80px;
//    }
//  }
//}
