.form-date-list {
  display: flex;
  flex-flow: column;
  gap: 7px;

  &__item {
    background-color: var(--bs-gray-100);
    padding: 10px;
    border-radius: 7px;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }
  }
}

.form-multiple-dates {
  margin-bottom: 24px;
  margin-top: 0;
}
