@import 'all';

.content-blocks__item {
  position: relative;
}

.guide-container {
  @include abs(0, 0, auto, 0);
  border-top: 1px solid var(--bs-code-color);
  margin-top: calc(var(--section-inset-value) * -0.5);
  z-index: 2;

  .guide-label {
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .block-overflow-x-hidden {
    overflow-x: hidden;
  }
}
