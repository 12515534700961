app-image {
  display: contents;

  img {
    aspect-ratio: var(--aspect-ratio);
    background-color: var(--bs-gray-200);
    height: 100%;
  }
  .object-fit {
    object-fit: cover;
  }
}
