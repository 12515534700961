.heading-squiggle {
  fill: currentColor;
  display: block;
  width: 100%;
  height: 0.3em;
  min-height: 0;
  text-align: inherit;
  padding: 0.1em 0 0;

  .icon {
    display: inline-block;
    width: 0.5em;
    position: relative;
    height: 0.2em;
    vertical-align: top;
  }
}
