.page-card {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: $border-radius-lg;
  transform-origin: center center;
  transition: transform 0.2s ease-in-out;
  aspect-ratio: 7 / 9;

  &:focus-visible {
    transform: scale(1.02);
  }

  @media (hover: hover) {
    &:hover {
      // .initiative-card__figure img {
      transform: scale(1.02);
      // }
    }
  }

  &__title {
    position: absolute;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.737) 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
    display: flex;
    width: 100%;
    align-items: end;
  }
}

.has-no-img {
  background-image: linear-gradient(301deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
}
