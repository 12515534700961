@each $name, $value in $button-sizes {
  .button--#{$name} {
    --button-size: #{rem($value)};
    --button-padding-x: #{rem($value * 0.5)};

    @include get-font-size(body-#{$name});

    &.button-link {
      padding-left: 0;
      padding-right: 0;
    }

    &.button-icon {
      @include size(rem($value));
      min-width: rem($value);
    }
  }
}

.button--xs.button-icon {
  @include size(rem(24px));
  min-width: rem(24px);
  font-size: 11px;
}
