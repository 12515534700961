.auth-layout {
  min-height: 100dvh;

  &__main {
    position: relative;
    z-index: 2;
  }

  &__background {
    --background-height: 50%;
    z-index: 1;

    @include abs(0);

    svg {
      width: 100%;
      position: absolute;
      bottom: var(--background-height);
    }

    &::after {
      content: '';
      height: var(--background-height);
      width: 100%;
      background-color: var(--bs-secondary);
      display: block;
      bottom: 0;
      position: absolute;
    }
  }
}
