.file-upload {
  background: var(--bs-gray-200);
  border: 2px dashed var(--bs-gray-300);
  border-radius: 5px;
  padding: 20px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;

  .file-name-preview-label {
    word-break: break-all;
  }

  &.is--drag-over {
    background: var(--bs-primary-200);
    border: 2px solid var(--bs-primary);
  }
  &.is--not-empty {
    // background: var(--bs-primary-200);
    border: 1px solid var(--bs-primary);
    justify-content: flex-start;
  }

  .custom-field--invalid & {
    border-color: var(--mat-warn-color, #b71721);
    background-color: lighten(theme-color(danger), 55%);
  }

  &__file {
    display: flex;
    align-items: center;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      text-align: center;

      &__actions {
        justify-content: center;
      }
    }

    &__image {
      border: 1px solid var(--bs-primary-400);
      border-radius: 5px;
      background: white;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        width: 33%;
        max-width: 180px;
      }

      img {
        max-width: 180px;
        max-height: 150px;
        margin: auto;
        object-fit: contain;
      }
    }

    &__meta {
      .text-truncate {
        max-width: 300px;
      }
      @include media-breakpoint-up(md) {
        max-width: 77%;
      }
    }

    .button.button-link {
      height: auto;
      overflow: visible;
    }
  }

  a {
    cursor: pointer;
  }
}

.truncate-full-width {
  display: flex;
  align-items: center;

  &__content {
    flex: 1;
    min-width: 0;

    > * {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
