@each $name, $value in $icon-sizes {
  .icon-#{$name} {
    width: rem($value);
    height: rem($value);
    min-width: rem($value);
    min-height: rem($value);

    svg {
      @include size(rem($value));
      min-width: rem($value);
      min-height: rem($value);
    }
  }
}
