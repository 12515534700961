.contact-card {
  display: flex;
  border-radius: $border-radius-lg;
  align-items: center;

  &__figure {
    max-width: 165px;
    position: relative;
    padding: 10px;

    img {
      width: 100%;
      border-radius: 100%;
      background-color: white;
    }

    .logo {
      display: block;
      padding: 20px;
      border-radius: 100%;
      background-color: white;
      overflow: hidden;

      &.has--both {
        position: absolute;
        bottom: 0;
        width: 70px;
        transform: translate(-7%, 7%);
      }

      img {
        object-fit: contain;
        border-radius: 0;
      }
    }
  }
  &__info {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: space-around;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    text-align: center;
  }

  .contact-card__info__content {
    display: flex;
    @include media-breakpoint-down(md) {
    }
  }
}
