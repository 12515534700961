.button.button-outline {
  background: transparent;
  color: var(--button-background);
  border-color: var(--button-background);

  app-icon .icon,
  fstr-icon .icon {
    fill: currentColor;
  }

  @media (hover: hover) {
    &:hover {
      background: var(--button-background-alpha);
      color: var(--button-background-darker);
      border-color: var(--button-background-darker);
    }
  }
}
