@import './topbar-utils';
@import './topbar-base';
@import './topbar-bar';
@import './topbar-overlay';
@import './hamburger-icon';

.theme-list-item-link {
  &.mat-mdc-menu-item {
    --mat-menu-item-hover-state-layer-color: var(--bs-gray-100);
    border-bottom: 1px solid var(--bs-gray-300);
    padding: 0.6rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: var(--bs-dark);
    font-weight: bold;

    &.is--current {
      background-color: var(--bs-primary) !important;
      color: var(--bs-light) !important;

      .chip {
        --chip-color: var(--bs-primary);
        --chip-background: white;
      }
    }
  }
}
