.event-header {
  --meta-width: 150px;

  background-color: var(--bs-primary);
  color: white;

  &__figure {
    position: relative;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-flow: column;

      .event-meta-bar {
        order: 10;
        width: -webkit-fill-available;
        max-width: none;
        margin: spacer(3) 10px -30px 10px;
        box-shadow: $box-shadow;
        align-items: center;
      }
    }

    &-wrapper {
      margin-bottom: -200px;
    }

    .event-meta-bar {
      z-index: 2;

      @include media-breakpoint-down(sm) {
        .event-date {
          margin-right: 30px;
          margin-left: 10px;
        }
      }
      @include media-breakpoint-up(sm) {
        margin-right: calc(var(--meta-width, 150px) * -0.5);
      }
    }
  }

  &__background {
    display: block;
    margin-bottom: 100px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 70px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }
}

.event-header-image-tile {
  &__inner {
    position: relative;
    border-radius: $border-radius-lg;
    overflow: hidden;
  }
  &__image {
    display: block;

    @include media-breakpoint-down(sm) {
      order: 20;
    }
  }
  &__caption {
    @include abs(auto, 0, 0, 0);
    padding: 20px 30px;
    text-align: right;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.737) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.event-meta-bar {
  background-color: white;
  border-radius: $border-radius-lg;
  color: var(--bs-primary);
  display: flex;
  text-align: center;
  line-height: 1.2;
  padding: spacer(3);
  gap: spacer(2);
  max-width: var(--meta-width, 150px);
  width: 100%;
  justify-content: space-evenly;

  @include media-breakpoint-up(sm) {
    flex-flow: column;
    padding: spacer(4);
    gap: spacer(4);
  }
}
