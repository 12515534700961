.content-slider {
  @supports (width: fill-available) or (width: -webkit-fill-available) {
    @include media-breakpoint-up(lg) {
      --outset: -100px;
      // max-width: max-content;
      max-width: inherit;
      margin-left: var(--outset);
      margin-right: var(--outset);
      width: -webkit-fill-available;
    }
    @include media-breakpoint-up(xxl) {
      --outset: -150px;
    }
  }

  // reset markdown img styles
  img {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}

.swiper-container {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &:hover .swiper-navigation {
    opacity: 1 !important;
  }
}

.swiper-navigation {
  @include abs(0, 0, 0, 0);
  margin: auto;
  z-index: 2;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 10px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;

  .button {
    pointer-events: auto;
    &:disabled {
      opacity: 0;
    }
  }
}

.swiper-thumb-slide {
  --focus-color: transparent;

  &.swiper-slide-thumb-active .thumb-slide-button {
    --focus-color: var(--bs-primary);
  }

  .thumb-slide-button {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    &::after {
      content: '';
      @include abs(0);
      border: 4px solid var(--focus-color);
      z-index: 2;
      border-radius: 10px;
    }

    &:focus-visible {
      --focus-color: red;
      outline: none;
    }

    &:hover {
      --focus-color: color-mix(in srgb, var(--bs-primary) 50%, transparent);
    }
  }
}

.swiper-slide {
  position: relative;

  &__caption {
    position: absolute;
    bottom: 10px;
    z-index: 2;
    background-color: color-mix(in srgb, var(--bs-primary) 80%, transparent);
    left: 10px;
    right: 10px;
    border-radius: 10px;
    margin-top: 10px;
    backdrop-filter: blur(2px);
    font-size: 14px;
    padding: 12px;
    color: white;

    *:last-child {
      margin-bottom: 0;
    }
  }
}
