.form-location__field {
  .mat-form-field-infix {
    border-top: 0 !important;
  }
}

.form-location {
  .form-map-container {
    height: 300px !important;
  }
}

.form-location {
  .form-location__hidden-formfield {
    position: absolute;
    pointer-events: none;
    .mat-form-field-wrapper {
      padding-bottom: 0;
      height: 0px;
      overflow: hidden;
    }
  }
}

.marker-list-item {
  background-color: var(--bs-gray-100);
  border-radius: 3px;
  padding: 0.5em 0.8em;
  display: flex;
  justify-content: space-between;
}

google-map.is--disabled {
  pointer-events: none;
  opacity: 0.4;
  filter: grayscale(1);
}
