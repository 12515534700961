.stories-overview {
  --mdc-circular-progress-active-indicator-color: white;

  &__toggle-view {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    pointer-events: none;
    display: flex;
    align-items: center;
  }
}

.stories-overview__top-wave .bg-wave {
  margin-top: -20px;

  @include media-breakpoint-down(sm) {
    margin-bottom: -10px;
    height: 80px;
  }
}

.story-list {
  row-gap: var(--bs-gutter-x);

  &__item {
    @include media-breakpoint-up(md) {
      &:nth-child(3n + 2) {
        margin-top: 50px;
        margin-bottom: -50px;
      }
    }

    @include media-breakpoint-between(sm, md) {
      &:nth-child(odd) {
        margin-top: 50px;
        margin-bottom: -50px;
      }
    }
  }
}

.story-overview {
  --background-height: 100vh;
  --background-max-height: 60%;

  @include media-breakpoint-down(sm) {
    --background-max-height: 100vh;
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  .stories-overview__bottom-wave {
    @include abs(0, 0, null, 0);
    background-color: var(--bs-secondary);
    display: block;
    height: var(--background-height);
    max-height: var(--background-max-height);
    z-index: 0;
    display: flex;
    align-items: flex-end;

    &__shape {
      transform: rotate(180deg) translateY(-100%);
      display: block;
    }

    @include media-breakpoint-down(sm) {
      // margin-bottom: -10px;
      height: 80px;
    }
  }
}
