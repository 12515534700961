.button.button-link {
  background: transparent;
  color: var(--button-background);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;

  app-icon,
  fstr-icon {
    flex-shrink: 0;

    .icon {
      fill: currentColor;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: var(--button-background-darker);
    }
  }
}
