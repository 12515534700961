.search__button {
  --search-button-size: 5em;
  border-radius: 100%;
  font-size: 2.5rem;
  width: var(--search-button-size);
  height: var(--search-button-size);

  @include media-breakpoint-down(lg) {
    --search-button-size: 4.2em;
  }
  @include media-breakpoint-down(sm) {
    --search-button-size: 3.2em;
  }

  &-txt {
    letter-spacing: -0.1em;
  }
}
