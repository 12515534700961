.swiper-slide {
  max-width: 300px;
  display: flex;
  height: auto;

  @include media-breakpoint-up(md) {
    max-width: 400px;
  }

  &__card {
    display: flex;
    height: 100%;
    width: 100%;
  }
}
