.free-mosaic-list {
  --spacing-offset-grid: 90px;
  --bs-gutter-y: var(--bs-gutter-x);

  @include media-breakpoint-up(xl) {
    padding-bottom: var(--spacing-offset-grid);
  }

  &__item {
    @include media-breakpoint-between(xsm, lg) {
      &:nth-child(2n + 2) {
        margin-top: calc(var(--spacing-offset-grid) + var(--bs-gutter-y));
        margin-bottom: calc(var(--spacing-offset-grid) * -1);
      }
    }

    @include media-breakpoint-up(xl) {
      &:nth-child(3n + 2) {
        margin-top: calc(var(--spacing-offset-grid) + var(--bs-gutter-y));
        margin-bottom: calc(var(--spacing-offset-grid) * -1);
      }
    }

    @include media-breakpoint-down(sm) {
      &.type--more {
        margin-top: var(--bs-gutter-y);
        margin-bottom: 0;
      }
    }
  }
}

.free-mosaic-list__item {
  .story-card {
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.mosaic-menu-item {
  height: rem(60px);
}
