blockquote {
  p {
    // padding-left: 20px;
    display: contents;
  }
  color: var(--bs-primary);
  position: relative;
  padding-left: 1.5ex;
  margin: 5ex 0 5ex 0;
  font-weight: 900;
  font-family: $font-family-sans-serif;
  // text-align: center;

  &::after,
  &::before {
    content: '“';
    font-size: 1.3em;
    // font-family: arial;
    line-height: 0;
    line-height: 0;
    font-weight: bold;
    // opacity: 0.2;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0.7em;
    // @include media-breakpoint-down(sm) {
    //   top: 0px;
    // }
  }

  &::after {
    // position: absolute;
    // right: 0;
    // bottom: -9px;
    content: '”';
    // @include media-breakpoint-down(sm) {
    //   bottom: -30px;
    // }
  }

  * {
    font-size: map-get($font-sizes, 6);
    font-weight: bold;
    line-height: 1.6;
    margin: 0 !important;
    text-align: left;

    @include media-breakpoint-down(md) {
      font-size: rem(18);
    }
    @include media-breakpoint-down(sm) {
      // padding-left: 0px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    // padding-left: 0px;
  }
}
