.text-max-width {
  max-width: map-get($text-max-widths, sm);

  @each $size, $width in $text-max-widths {
    &-#{$size} {
      max-width: $width;
    }
  }

  @each $bp-name, $bp-width in $grid-breakpoints {
    @each $size, $width in $text-max-widths {
      @media screen and (min-width: #{$bp-width}) {
        &-#{$bp-name}-#{$size} {
          max-width: $width !important;
        }
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.font-primary {
  font-family: $font-family-serif;
  line-height: 1;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $font-family-serif;
    line-height: 1;
  }
}
.font-secondary {
  font-family: $font-family-sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $font-family-sans-serif;
  }
}

.text-decoration-none {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.block-link {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

@each $name, $color in map-merge($theme-colors, $colors) {
  .text-#{$name} {
    color: var(--bs-#{$name}) !important;
  }
}

.fw-semi {
  font-weight: 600 !important;
}

.text-muted-light {
  color: #757575;
}

// for 3 items
@for $i from 1 through 4 {
  .max-lines-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
