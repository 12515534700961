.meta-list {
  display: flex;
  flex-wrap: wrap;
  gap: spacer(1);
}

.initiative-detail {
  &__figure {
    position: relative;

    img {
      object-fit: cover;
    }

    &__wrapper {
      position: relative;
      background-color: var(--bs-gray-100);
      min-height: 200px;
      max-height: 700px;
      overflow: hidden;

      img {
        max-height: 700px;

        @include media-breakpoint-up(md) {
          min-height: 450px;
        }
      }

      @include media-breakpoint-down(md) {
        margin-left: -12px;
        margin-right: -12px;
        width: auto;
      }

      @include media-breakpoint-up(md) {
        border-radius: $border-radius-lg;
      }

      .bg-wave {
        @include abs(100%, null, null, -2px);
        width: calc(100% + 4px);
        transform: translate(0, -75%);
        height: 110px;

        @include media-breakpoint-down(sm) {
          height: 60px;
        }

        @include media-breakpoint-up(xxl) {
          height: 140px;
        }
      }
    }
  }
}

.initiative-detail .initiative__related-stories {
  --color-subtitle: var(--bs-light);

  .bg-wave {
    height: 100px;
  }
  @include media-breakpoint-down(md) {
    .bg-wave {
      height: 50px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: -170px;
  }

  .bg--end .bg-wave {
    transform: scaleY(-1) scaleX(-1);
  }
}

.initiative-detail__edit {
  display: inline-block;
  width: auto;
}
.publish-alert {
  a {
    color: var(--bs-secondary);
  }
}
