.profile-header {
  background-color: var(--bs-primary);

  &__background {
    display: block;
    margin-bottom: 0px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 70px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }

  &__image {
    background-color: var(--bs-secondary);
    @include size(400px);
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    position: relative;
    @include media-breakpoint-down(md) {
      @include size(250px);
      margin-top: 40px;
    }

    &__edit {
      position: absolute;
      margin: auto;
      top: 0px;
      left: -30px;
      bottom: 0px;
      box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.content-type-amount {
  @include size(40px);
  background-color: var(--bs-primary);
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 50%;
}
