@import 'all';
.theme-header {
  background-color: var(--bs-secondary);
  border-radius: $border-radius-lg $border-radius-lg 0 0;

  &-container {
    max-width: 1495px;
    margin: auto;
  }

  &__image {
    height: 100%;
    display: flex;

    img {
      object-fit: cover;
      border-radius: $border-radius-lg;
      height: 100%;
    }
  }
  &__heading {
    display: inline-block;
    vertical-align: middle;

    .chip--theme {
      @include media-breakpoint-up(md) {
        align-items: unset;
        transform: translateY(-3px);
      }
    }
    @include media-breakpoint-down(md) {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}
