//.event-detail {
//  &__figure {
//    position: relative;

//    img {
//      object-fit: cover;
//    }

//    &__wrapper {
//      position: relative;
//      background-color: var(--bs-gray-100);
//      min-height: 200px;
//      max-height: 700px;
//      overflow: hidden;

//      img {
//        max-height: 700px;
//      }

//    }
//  }
//}

.event-detail .event__related-events {
  --color-subtitle: var(--bs-light);

  .bg-wave {
    height: 100px;
  }
  @include media-breakpoint-down(md) {
    .bg-wave {
      height: 50px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: -170px;
  }

  .bg--end .bg-wave {
    transform: scaleY(-1) scaleX(-1);
  }
}

.event-detail__edit {
  display: inline-block;
  width: auto;
}

.event-detail__figure__caption {
  @include abs(100%, 0, null, null);
  transform: translateY(-100%);

  @include media-breakpoint-down(md) {
    transform: translateY(-50%);
  }
}

.event-date-list {
  display: flex;
  flex-flow: column;
  gap: 15px;

  &__item {
    width: 100%;
    padding-bottom: 15px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--bs-gray-300);
    }
  }
}
