.mat-mdc-menu-panel {
  --mat-menu-container-color: var(--bs-light);
  background: var(--bs-light);
}

.dropdown-menu-panel {
  max-width: 540px !important;
  border-radius: $border-radius-sm !important;
  margin-left: -140px;
  margin-top: 15px;
  transform-origin: top !important;

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
  }
}
