.main-nav--active {
  overflow: hidden;
  height: 100vh;
}

:root {
  --topbar-height: 84px;
  --topbar-height-total: 84px;
  @include media-breakpoint-down(md) {
    --topbar-height: 53px;
    --topbar-height-total: 53px;
  }
}

.topbar:not(.set--fixed) {
  @include media-breakpoint-up(md) {
    --topbar-height-total: 126px;
  }
}
