app-initiative-card {
  display: contents;
}

.initiative-card {
  @include size(100%);

  @include media-breakpoint-down(sm) {
    border-radius: $border-radius-sm;
  }

  .block-link {
    display: flex;
    height: 100%;
    flex-direction: column;
    border-radius: $border-radius-lg;
    overflow: hidden;
    background-color: var(--bs-light);
    transform-origin: center center;
    transition: transform 0.2s ease-in-out;

    &:focus-visible {
      transform: scale(1.02);
    }

    @media (hover: hover) {
      &:hover {
        // .initiative-card__figure img {
        transform: scale(1.02);
        // }
      }
    }
  }

  &.set--inverted {
    .date {
      color: white;
    }

    .block-link {
      background-color: var(--bs-primary);
      color: var(--bs-light);
    }
  }
}

.initiative-card__figure {
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    @include abs(0);
    display: flex;
    gap: spacer(2);
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.initiative-card__figure__caption {
  @include abs(0, 0, null, null);
  padding: spacer(2);
  transform-origin: top right;

  .button {
    margin-left: 4px;

    @include media-breakpoint-down(md) {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
    }
  }
}

.initiative-card__title {
  text-transform: none;
  white-space: initial;
}

.initiative-card__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  @include media-breakpoint-down(sm) {
    padding: spacer(3) spacer(3) 0;
  }
}

.initiative-card__text {
  display: -webkit-box;
  display: -moz-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down(sm) {
    font-size: rem(14);
  }
}

.initiative-card__footer {
  display: flex;
  align-items: flex-end;
  gap: 7px;

  @include media-breakpoint-down(sm) {
    .button app-icon {
      transform: translateY(2px);
    }
  }

  @include media-breakpoint-up(sm) {
    margin-top: auto;
    margin-bottom: 0;
    padding: spacer(4) spacer(4) spacer(2);
  }
}

.theme-list {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;

  @include media-breakpoint-down(sm) {
    justify-content: flex-end;
  }
}
