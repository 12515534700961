/**
 * Section class for inner padding, outer margin or negative margin.
 * The negative option is used to pull a section over another to create overlapping content.
 */

[class^='section-'] {
  position: relative;
}

[class^='section-negative-'] {
  z-index: 1;
}

//$section-spacing: min(150px, 15vw);
$section-spacing: clamp(50px, 100px, 15vw);

:root {
  --section-spacing: #{$section-spacing};
}

@each $type, $prop in (inset: padding, outset: margin, negative: margin) {
  $class: 'section-#{$type}';

  // Use negative values for pull
  @if $type == negative {
    $section-spacing: calc(#{$section-spacing} * -1);
  }

  .#{$class}-y {
    #{$prop}-top: var(--section-spacing);
    #{$prop}-bottom: var(--section-spacing);
  }

  .#{$class}-t {
    #{$prop}-top: var(--section-spacing);
  }

  .#{$class}-b {
    #{$prop}-bottom: var(--section-spacing);
  }
}
