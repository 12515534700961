*:not(input):not([contenteditable]):not(mat-form-field *):focus-visible {
  outline: 2px solid red !important;
}

.skiplink {
  //Hide the link for normal users
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  z-index: 9999;

  &:focus {
    width: auto;
    height: auto;
    clip: auto;
    overflow: visible;
    z-index: 9999;
  }
}
