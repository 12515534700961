@each $name, $value in $button-sizes {
  .chip--#{$name} {
    --chip-size: #{rem($value)};
  }
}
.chip--lg {
  @include media-breakpoint-down(md) {
    --chip-size: 3rem;
  }
}
