.story-detail .themes-list {
  gap: spacer(1);
  display: flex;
}
.story-detail .meta-list {
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}
.story-detail {
  &__figure {
    position: relative;

    &__wrapper {
      position: relative;
      background-color: var(--bs-gray-100);
      overflow: hidden;
      border-radius: $border-radius-lg;

      @include media-breakpoint-down(sm) {
        border-radius: 0px;
      }

      img {
        min-height: 200px;
        max-height: 500px;
      }

      @include media-breakpoint-down(md) {
        margin-left: -12px;
        margin-right: -12px;
        width: auto;
      }

      .bg-wave {
        @include abs(100%, null, null, -2px);
        width: calc(100% + 4px);
        transform: translate(0, -75%);
        height: 110px;
        --offset-correction: 0;

        @include media-breakpoint-down(sm) {
          height: 60px;
        }

        @include media-breakpoint-up(xxl) {
          height: 140px;
        }
      }
    }
  }
  &__author {
    display: flex;
    align-items: center;
    gap: 10px;

    .author-squiggle {
      color: var(--bs-primary);
      fill: var(--bs-primary);
      width: 1.5em;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
    }
  }
}

.story-detail__edit {
  display: inline-block;
  width: auto;
}

.story-detail__figure__caption {
  @include media-breakpoint-up(md) {
    @include abs(100%, spacer(3), null, null);
  }

  @include media-breakpoint-down(md) {
    position: relative;
  }
}

.meta-list-secondary {
  display: inline-flex;

  &__item {
    //flex-basis: 0;
    //flex-grow: 1;

    &--date {
      white-space: nowrap;
      min-width: 12em;
    }
  }
}
