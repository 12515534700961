:root {
  --color-subtitle: var(--bs-secondary);
  --color-title: var(--bs-primary);
}
.related-stories__subtitle {
  color: var(--color-subtitle);
}

.related-stories__title {
  color: var(--color-title);
}
