:root {
  --chip-size: #{$button-size};
  --chip-mobile-size: 45px;
}

.chip {
  --chip-background: var(--bs-light);
  --chip-color: var(--bs-primary);

  @include size(var(--chip-size));
  min-width: var(--chip-size);
  background-color: var(--chip-background, #fff);
  color: var(--chip-color);
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  padding: 7px 0;
  fill: var(--bs-primary);
  transition: background 0.35s ease;
  align-items: center;

  font-size: calc(var(--chip-size) * 0.6);

  span + fstr-icon,
  span + app-icon,
  span + .css-icon {
    margin-inline-start: rem(8px);
  }

  .icon-chip + span,
  fstr-icon + span,
  app-icon + span,
  .css-icon + span {
    margin-inline-start: rem(8px);
  }

  fstr-icon .icon,
  app-icon .icon {
    fill: var(--chip-color) !important;
  }

  @include media-breakpoint-down(md) {
    @include size(var(--chip-mobile-size));
    min-width: var(--chip-mobile-size);
  }

  &--label {
    width: auto;
    padding: 9px;
    font-weight: 700;
    border-radius: 50px;
    display: inline-flex;
    line-height: 1;
  }
}

// theme icon tweaks
//.chip--theme app-icon {
//  --icon-size: 1.5em;
//  width: var(--icon-size);
//  height: var(--icon-size);

//  @include media-breakpoint-down(md) {
//    --icon-size: 1.3em;
//  }
//}
