@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .chip.chip--#{$name} {
    --chip-background: #{$bg-color};
    --chip-color: #{$font-color};

    //.icon * {
    //  fill: $font-color;
    //}
  }
}
