// Imports

// Bootstrap
$font-size-base: 16;

$font-family-sans-serif: 'Montserrat', sans-serif;
// $font-family-sans-serif: 'Montserrat', sans-serif;

$font-family-serif: 'Alberto', 'Trebuchet MS', sans-serif;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 110px,
  2: 80px,
  3: 50px,
  4: 42px,
  5: 32px,
  6: 25px,
  7: 16px,
  body-xxs: 12px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 18px,
  body-lg: 24px,
);

$headings-font-family: $font-family-serif;
$headings-font-weight: 500;
$headings-line-height: 0.9;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 400;

$lead-font-size: map-get($font-sizes, body-md);
$lead-font-weight: 700;

$text-muted: var(--bs-gray-600);

$link-color: theme-color(primary);

// Other
//? Put own extra variables here
