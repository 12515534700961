.app {
  position: relative;
}

.app__router {
  position: relative;
  min-height: 100dvh;

  &.is--404 {
    min-height: 150px;
  }
}
