.topbar {
  @include abs(0, 0, null, 0);

  z-index: 99;
  max-width: 100vw;
  position: sticky;
  overflow: hidden;

  &.is--open {
    background-color: var(--bs-light);

    .topbar__logo {
      display: block !important;
    }
  }

  &__logo {
    .hide--topbar-logo & {
      display: none;
    }
  }

  &__logo img {
    max-height: 60px;
    width: auto;

    @include media-breakpoint-down(md) {
      max-height: 44px;
    }
  }

  &__bar {
    padding: 12px 0;
    z-index: 999;
    position: relative;
    background-color: var(--bs-primary);
    min-height: var(--topbar-height);
    display: flex;
    color: var(--bs-primary-overlay);

    .is--open & {
      background-color: transparent;
    }

    @include media-breakpoint-down(md) {
      padding: 7px 0;
    }

    &__inner {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      height: 100%;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    gap: map-get($spacers, 4);

    .hide--topbar-logo & {
      @include media-breakpoint-up(xl) {
        margin-left: -#{map-get($spacers, 4)};
      }
    }

    //Should match topbar.navbar-list
    @include media-breakpoint-down(xl) {
      justify-content: flex-end;
    }
  }

  &.is--open {
    .navbar-list {
      opacity: 0;
      pointer-events: none;
      max-height: 1em;
      min-width: 0;
      max-width: 100px;
    }
  }
  &.is--open {
    .topbar__bar {
      box-shadow: none;
    }
  }
}
