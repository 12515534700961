.topbar__search {
  fill: white;
}

.navbar-list {
  display: flex;
  gap: 20px;
  min-width: 60%;
  justify-content: space-around;
  align-items: center;

  //Should match topbar__nav
  @include media-breakpoint-down(xl) {
    display: none;
  }

  &__item {
    color: inherit;

    a,
    button {
      font-weight: bold;
      text-decoration: none;
      color: inherit;

      &.is--current,
      &[aria-expanded='true'] {
        text-decoration: underline;
        color: var(--bs-secondary-overlay);
      }
      app-icon {
        transition: all 0.3s;
      }
    }

    & button[aria-expanded='true'] {
      app-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.topbar__actions {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}

.main-nav--active .palette-light .topbar__actions {
  --bs-primary: var(--bs-secondary);
}

@keyframes topbar-slide-in {
  from {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes topbar-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.action-bar {
  background-color: var(--bs-muted);

  &__inner {
    display: flex;
    gap: var(--bs-gutter-x);
    justify-content: flex-end;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      gap: calc(var(--bs-gutter-x) * 2);
    }
  }

  &__item {
    position: relative;
  }

  .action-bar-link {
    padding: 10px 0;
  }
}

.action-bar__subnav {
  @include abs(100%, 0, null, null);
  z-index: 1000;
  background-color: var(--bs-secondary);
  padding: map-get($spacers, 3) map-get($spacers, 3);
  border-radius: $border-radius;
  min-width: 150px;
  transform: translateY(-4px);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.set--active {
    pointer-events: all;
    opacity: 1;
  }

  &::before {
    @include abs(0, map-get($spacers, 3), null, null);
    content: '';
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
    border-bottom: solid 6px var(--bs-secondary);
    transform: translateY(-99%);
  }
}
.button--nav-cta {
  background-color: map-get($theme-colors, 'tertiary');
  color: map-get($theme-colors, 'primary');

  &:hover {
    background-color: darken(map-get($theme-colors, 'tertiary'), 10%);
    color: map-get($theme-colors, 'primary');
  }
}
