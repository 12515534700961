// Boostrap
$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);

$border-color: var(--bs-gray-300);
$border-radius: rem(5px);
$border-radius-sm: rem(10px);
$border-radius-lg: rem(15px);
$border-radius-pill: 50rem;

// Other
//? Put own extra variables here
