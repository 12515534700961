.markdown-content {
  $markdown-font-sizes: (
    h1: 62px,
    h2: 52px,
    h3: 42px,
    h4: 42px,
    h5: 42px,
    h6: 42px,
  );

  $markdown-block-spacing-t: map-get($spacers, 4);
  $markdown-block-spacing-b: map-get($spacers, 6);

  // font-size: rem(20);

  // @include media-breakpoint-down(xl) {
  //   font-size: rem(18);
  // }

  // @include media-breakpoint-down(md) {
  //   font-size: rem(16);
  // }

  iframe,
  object,
  embed {
    width: 100%;
    height: auto;
  }

  img {
    max-width: 100%;
    border-radius: $border-radius-lg;
    max-height: 100vh;
    object-fit: contain;
    margin-bottom: 3ex;
    margin-top: 3ex;

    @supports (width: fill-available) or (width: -webkit-fill-available) {
      @include media-breakpoint-up(lg) {
        --outset: -100px;
        // max-width: max-content;
        max-width: inherit;
        margin-left: var(--outset);
        margin-right: var(--outset);
        width: -webkit-fill-available;
      }
      @include media-breakpoint-up(xxl) {
        --outset: -150px;
      }
    }
  }

  @import './blockquote';
  @import './image';
  @import './table';
  @import './type';
  @import './video';

  *:last-child {
    margin-bottom: 0;
  }
}

@import './simple-markdown';
@import './embed-removed';
