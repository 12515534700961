@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

app-icon,
fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1em;
  min-height: 1em;

  .icon {
    @include abs(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  @each $name, $color in $theme-colors {
    &.icon-#{$name},
    .icon-#{$name} {
      color: var(--bs-#{$name});
    }
  }

  &.animate--rotate {
    animation: spin 4s infinite linear;
  }
}
