app-content-block-default {
  display: block;
  container-type: inline-size;
}

.block-default {
  border-radius: $border-radius-lg $border-radius-lg 0 0;

  &__title {
    color: var(--bs-primary);
  }
  &__button {
    color: var(--bs-light);
    background-color: var(--bs-primary);
  }
  &.bg-primary {
    .block-default__title {
      color: var(--bs-light);
    }
    .markdown-content a:not(.button) {
      color: var(--bs-secondary);
    }
    .markdown-content {
      color: var(--bs-light);
    }
    .block-default__button {
      background-color: var(--bs-tertiary);
      color: var(--bs-primary);
    }
  }
}
