.call2action-story-card {
  display: flex;
  min-height: 30rem;
  transform-origin: center center;
  transition: transform 0.2s ease-in-out;

  border-radius: $border-radius-lg;
  flex-wrap: wrap;
  padding: spacer(2);
  width: 100%;

  @include media-breakpoint-up(sm) {
    padding: spacer(5);
    align-items: center;
  }

  &:hover {
    transform: scale(1.02);
  }
}
.call2action-story-card__figure {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='6' stroke-dasharray='10%2c 25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: $border-radius-lg;
  display: flex;
  align-items: center;
  justify-content: center;
  //max-width: 440px;
  position: relative;
  min-height: 30rem;

  @include media-breakpoint-up(sm) {
    padding: spacer(5);
  }

  @include media-breakpoint-down(sm) {
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='3' stroke-dasharray='10%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}

.call2action-story-card__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 27rem;
  @include media-breakpoint-down(sm) {
    padding: spacer(3) spacer(3) 0;
    margin-bottom: spacer(3);
  }

  &-btn {
    @include media-breakpoint-down(sm) {
      font-size: rem(20);
    }
  }
}

.call2action-story-card__title {
  @include media-breakpoint-down(sm) {
    font-size: rem(20);
    padding: 0 spacer(3);
  }

  // @include media-breakpoint-down(md) {
  //   font-size: rem(24);
  //   padding: 0 spacer(3);
  // }
}

.call2action-story-card__subtitle {
  line-height: 1.3;
  font-size: 2rem;
  width: 90%;

  @include media-breakpoint-down(sm) {
    font-size: rem(32);
  }

  @include media-breakpoint-down(xs) {
    font-size: rem(28);
  }
}
