@mixin scrollbar($track-color: var(--bs-secondary), $thumb-color: var(--bs-primary)) {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $thumb-color;
  }
  &::-webkit-scrollbar-thumb:hover {
    //background: #555;
  }
}
