.theme-button {
  --icon-inset-factor: -0.75;

  input[type='checkbox'] {
    display: none;
  }

  &__chip {
    border-radius: 50%;
    @include size(2em);
    min-width: 2em;
    border: var(--button-border-width) solid var(--button-background);
    margin-inline-end: 0.5em;

    .icon {
      @include abs(0.4em);
      @include size(-webkit-fill-available);
      fill: inherit !important;
    }
  }

  &:not(.button-outline) {
    .theme-button__chip {
      background-color: var(--button-color);
      fill: var(--button-background);
    }
  }
}

.form-themes-list {
  display: flex;
  flex-wrap: wrap;
  gap: rem(5px);
}

.form.ng-invalid .theme-chooser__input.custom-field--invalid {
  + .label {
    // color: var(--bs-error);
  }
}
