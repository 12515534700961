// Bootstrap
$container-max-widths: (
  sm: 800px,
  md: 960px,
  lg: 1000px,
  xl: 1140px,
  xxl: 1320px,
);

$container-padding-x: $grid-gutter-width * 0.5;

// Other
//? Put own extra variables here
$container-sizes: (
  xxs: 480px,
  xs: 635px,
  sm: 860px,
  md: 1200px,
  xl: 1400px,
  xxl: 1600px,
);
