.initiative-form__header {
  --wave-height: 100px;
  position: relative;
  padding-bottom: var(--wave-height);
  background-color: var(--bs-primary);
}
.initiative-form__wave .bg-wave {
  @include abs(null, null, 0, 0);
  @include size(102%, var(--wave-height));

  transform: skewY(-1);
}

.initiative-form__header__title {
}

.initiative-form {
  .form-actions-card {
    .card--actions {
      background-color: var(--bs-gray-100) !important;
    }
  }
}
