.card {
  padding: 2rem;
  border-radius: $border-radius-sm;
  background-color: var(--bs-gray-100);

  @include media-breakpoint-down(md) {
    padding: rem(20px);
  }
}

.card--actions {
  display: flex;
  gap: 20px;
  align-items: center;

  .avatar {
    overflow: hidden;
    border-radius: 50%;
    width: 90px;
    aspect-ratio: 1/1;
  }

  .card__main {
    flex: 1;
  }
}
