app-wave-cta {
  pointer-events: none;
}

.wave-cta {
  --wave-min-width: 940px;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    &.is--big-title .wave-cta__inner {
      transform: translate(-50%, 0);
      left: 50%;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    padding: 0 !important;

    @include media-breakpoint-down(lg) {
      transform: translateX(var(--bs-gutter-x));
    }

    //@include media-breakpoint-down(md) {
    //  padding-left: calc(var(--bs-gutter-x));
    //}
    @include media-breakpoint-down(md) {
      min-width: var(--wave-min-width);
      max-width: calc(200vw);
      // margin-bottom: -10vw;
    }
    @include media-breakpoint-down(xsm) {
      --wave-min-width: 540px;
      max-width: calc(200vw);
      // margin-bottom: -10vw;
    }

    &::before,
    &::after {
      content: '';
      background-image: url('/assets/gfx/footer-wavy-sides.svg');
      background-repeat: repeat-x;
      width: 100vw;
      z-index: 1;

      @include media-breakpoint-down(md) {
        min-width: var(--wave-min-width);
        max-width: calc(200vw);
      }
    }

    &::before {
      @include abs(0, 100%, 0, auto);
      background-position-x: 100%;
    }
    &::after {
      @include abs(0, auto, 0, 100%);
    }
  }
  &__text {
  }
  &__title {
    font-size: 260px;
    font-family: $font-family-serif;
  }
}

app-wave-cta {
  display: contents;

  svg {
    display: block;

    .st2 {
      fill: var(--bs-primary);
    }

    .st3 {
      font-family: $font-family-serif;
    }

    .st4 {
      font-size: 70px;
    }
  }
}

.fill-primary {
  fill: var(--bs-primary);
}

.clip {
  clip-path: url(#clippath);
}
