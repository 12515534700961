.fr-video {
  // @include aspect-ratio;
  position: relative;
  display: block;
  width: 100%;
  margin: 2ex 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: $border-radius-lg;

  @include media-breakpoint-up(xl) {
    --outset: -150px;

    padding-bottom: calc(56.25% + 150px);
    margin-left: var(--outset);
    margin-right: var(--outset);
    width: -webkit-fill-available;
  }

  iframe {
    // @extend .aspect-ratio-element;
    @include abs(0, 0, 0, 0);
    height: 100%;
    width: 100%;
    // min-height: 500px;
  }
}

.ql-video {
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: $border-radius-lg;
}
