.story-teaser-card {
  transform-origin: center center;
  transition: transform 0.2s ease-in-out;

  &.set--inverted {
    background-color: var(--bs-primary);
    color: var(--bs-light);

    .story-teaser-card__footer__link {
      color: currentColor;
    }

    a:hover {
      color: currentColor;
    }
  }

  &__header {
    position: relative;
  }
  &__title {
    line-height: 1.3;
  }
  &__subtitle {
    line-height: 1.3;
    margin-bottom: 0.5ex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__wrapper {
    @include abs(0, null, null, 0);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: spacer(4);
  }
  &__figure {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-lg;

    &::before {
      @include abs(0);

      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.15%, rgba(0, 0, 0, 0) 62.15%);
      content: '';
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: spacer(4);

    &__link {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      text-decoration: none;
      @include media-breakpoint-down(md) {
        flex-direction: row;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      // .initiative-card__figure img {
      transform: scale(1.02);
      // }
    }
  }

  .block-link {
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }
}
