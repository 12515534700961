em,
i {
  font-style: italic;
}

strong {
  font-weight: 800;
}

@each $name, $size in $markdown-font-sizes {
  * + #{$name},
  * + .#{$name} {
    margin-top: map-get($spacers, 6);
  }

  #{$name},
  .#{$name} {
    font-size: rem($size);
    margin-bottom: 0.4em;
    font-weight: 500;
    color: var(--bs-secondary);
    font-family: $font-family-serif;
    text-transform: none;

    * {
      color: var(--bs-secondary) !important;
    }
  }
}

ul,
ol {
  margin-left: 5px;
  color: var(--bs-primary);
  list-style: outside;
  padding-inline-start: map-get($spacers, 4);
  display: inline-block;
  margin-bottom: rem(20px);
  width: 100%;

  li {
    display: list-item;
    list-style-type: initial;
  }
}

ol li {
  list-style-type: decimal;
}

hr {
  margin: 1.5em 0;
}

a:not(.button) {
  color: var(--bs-primary-overlay);
  text-decoration: underline;
  padding: 0 0.2rem;
  font-weight: bold;
  color: var(--bs-primary);
}

p {
  margin-bottom: 3ex;
}
