.stories-map .map-container {
  min-height: calc(100vh - var(--topbar-height, 40px));
  width: 100% !important;
  position: relative;

  &__loading {
    @include abs(0);
    background-color: color-mix(in srgb, var(--bs-primary), transparent 40%);
    z-index: 2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 10px;
    font-weight: bold;
  }
}

.map-card-info-windows {
  .gm-style-iw.gm-style-iw-c {
    padding: 0 !important;
  }

  .gm-style .gm-style-iw-d {
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    overflow: hidden !important;
  }

  .gm-style .gm-style-iw-c {
    border-radius: 15px;
  }
}

.marker-card {
  border: 5px solid white;

  &__close {
    @include abs(0, 0, auto, auto);
    margin: 10px;
    z-index: 2;
  }
}

.sticky-map-controls {
  position: sticky;
  bottom: 0;
  z-index: 9;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}
