.confirm {
  text-align: center;
  &__inner {
    // padding: 1rem 1rem 2rem 1rem;
  }
}

.mat-dialog__header__title {
  font-weight: 700;
}

.confirm__message {
  color: var(--bs-dark);
}
.confirm-dialog {
  // overflow: hidden;
}

.confirm__footer {
  .button {
    min-width: 80px;
    justify-content: center;
  }
}
