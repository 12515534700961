.initiatives-overview {
  --mdc-circular-progress-active-indicator-color: white;
}

.overview {
  &__header {
    &__inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      align-items: flex-end;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__content {
      flex: 5;
    }
    &__action {
      flex: 1;
    }
  }
}

.overview-loading-state {
  text-align: center;
}

.overview-empty-state {
  text-align: center;
}

.initiatives-overview__subtitle {
  font-size: rem(12);

  @include media-breakpoint-up(sm) {
    font-size: rem(18);
  }
}

.select-lg {
  &.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label {
      font-size: rem(14px);
      color: var(--bs-primary);
    }
  }

  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: var(--bs-gray-100);
      border: none;
      border-radius: 100px !important;

      @include media-breakpoint-up(md) {
        padding-left: 22px !important;
        padding-right: 22px !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .mat-form-field-appearance-fill .mat-form-field-infix {
      padding: 0.15em 0 0.55em 0;
    }
  }

  .mat-select-value-text {
    font-size: rem(18px);
    font-weight: bold;
    color: var(--bs-primary);

    @include media-breakpoint-up(md) {
      font-size: rem(22px);
    }
  }

  .mat-select-arrow {
    opacity: 0;
  }
}

.mat-select-panel.select-lg__panel {
  box-shadow: $box-shadow;
  border-radius: 30px;
  border: 1px solid alpha(var(--bs-primary), 0.2);

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: var(--bs-primary);
    color: white;
    font-style: normal;
    font-weight: 500;
  }

  .mat-option {
    line-height: 3.5em;
    height: 3.5em;

    &:first-child {
      line-height: 4em;
      height: 3.5em;
      align-items: flex-start;
    }

    &:not(:last-child) {
      border-bottom: 1px solid alpha(var(--bs-primary), 0.2);
    }
  }
}

.initiatives-overview__wave .bg-wave {
  height: 120px;
  @include media-breakpoint-down(md) {
    height: 80px;
  }
}

.initiatives-overview__wave.type--top .bg-wave {
  margin-top: -20px;
}

.initiatives-overview__wave.type--end {
  .bg-wave {
    transform: scaleY(-1) scaleX(-1);
  }
}

.order-by {
  width: 100%;
}
