.button.button-with-chip {
  padding-left: 0.2em;
  border-radius: 100px;

  .chip {
    margin-right: 0.5em;

    --chip-size: calc(var(--button-size) * 0.8);
    --chip-mobile-size: calc(var(--button-size) * 0.8);

    app-icon,
    fstr-icon {
      margin-inline-start: 0 !important;
    }
  }
}
