
.mat-form-field.mat-form-field-appearance-fill,
.mat-form-field.mat-form-field-appearance-standard,
.custom-field {
  --mat-form-field-color: var(--mat-primary-500);
  --mat-form-field-background: var(--bs-primary-100);

  --mat-border-color: var(--mat-form-field-color);
  --mat-border-width: 1px;
  --mat-border-radius: 10px;

  --mat-label-color: var(--bs-dark);
  --mat-label-font-size: 1.1em;
  --mat-label-height: 29px;
  --mat-label-bottom-spacing: .5em;

  --mat-input-height: 46px;
  --mat-inset-x: 10px;
  
  --mat-error-height: 20px;
  
  // mat icon settings
  --mat-icon-size: 1.2em;
  --mat-icon-spacing: .5em;

  &.mat-form-field-invalid {
    --mat-border-color: var(--mat-warn-500);
  }

  &:not(.has--label) {
    --mat-label-height: 0;
  }
}