.stepper-button {
  --stepper-status-color: var(--bs-gray-400);

  color: var(--stepper-status-color);
  display: flex;
  height: var(--button-height);
  background-color: var(--bs-gray-100);
  width: 100%;
  border-radius: $border-radius-sm;
  padding: rem(10px);
  align-items: center;
  transition: background 0.2s;

  &:hover {
    background-color: var(--bs-gray-200);
  }

  &__figure {
    min-width: 2em;
    @include size(2em);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__chip {
    background: var(--stepper-status-color);
    color: white;
    fill: white;
    min-width: 2em;
    @include size(2em);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.2s;
  }

  &__content {
    flex: 1;
    text-align: left;
  }

  &.is--active {
    --stepper-status-color: var(--bs-primary);
  }

  &.is--untouched {
    --stepper-status-color: var(--bs-gray-400);

    .stepper-button__chip {
      @include size(1.4em);
      min-width: 1.4em;
    }
  }
  &.is--invalid {
    --stepper-status-color: var(--bs-danger);
  }
  &.is--valid {
    --stepper-status-color: var(--bs-success);

    .stepper-button__chip {
      @include size(1.4em);
      min-width: 1.4em;
    }
  }
}

.stepper-list {
  display: flex;
  flex-flow: column;
  gap: spacer(2);
}

.form-stepper {
  .form.row {
    --bs-gutter-y: 0;
  }

  .fieldset {
    &__main {
      &__inner {
        background-color: var(--bs-gray-100);
        padding: 15px;
        border-radius: $border-radius-sm;
      }
    }
  }

  .fieldset__main__inner {
    //row-gap: 10px;
    //display: flex;
    //flex-flow: column;
  }

  .fieldset__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.form-stepper__hassubmit {
  border-radius: $border-radius;
}
