// Functions
@import 'functions/all';

// SVG helpers
// @import '/src/assets/dist/svg/scss/_svg-css.scss';

// Config
@import './bootstrap-config';
@import './bootstrap-other';

// Mixins
@import 'mixins/all';

//
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import './material/all';

// Applying config to base tags etc.
@import 'base/all';
@import 'themes/all';

@import 'utils/all';
@import 'components/all';

@import '_accessibility-helpers';

// Angular components
@import 'src/app/app.module.scss';

//* {
//  @include scrollbar();
//}
