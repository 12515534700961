app-event-teaser-card {
  @include media-breakpoint-up(lg) {
    height: 100%;
  }
}
.event-teaser-card {
  transform-origin: center center;
  transition: transform 0.2s ease-in-out;
  @include media-breakpoint-up(lg) {
    height: 100%;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.02);
    }
  }

  &__inner {
    display: flex;
    padding: spacer(2);
    justify-content: space-between;
    height: 100%;
  }

  &__header {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__date {
    justify-content: center;

    @include media-breakpoint-up(sm) {
      min-width: 11em;
    }
    @include media-breakpoint-up(xxl) {
      min-width: 13em;
    }
  }
}
