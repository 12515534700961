.content-card {
  --outset: -100px;
  max-width: inherit;
  margin-left: var(--outset);
  margin-right: var(--outset);
  width: -webkit-fill-available;

  @include media-breakpoint-down(lg) {
    --outset: -20px;
    padding-left: 0;
    padding-right: 0;
  }

  &__figure {
    fstr-image {
      img {
        margin: 0;
        --outset: 0;
        max-width: 100%;
        border-radius: 0.625rem;
        object-fit: cover;
        max-height: 400px;
        @include media-breakpoint-down(md) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
