.call2action-event-card {
  display: flex;

  border-radius: $border-radius-lg;
  flex-wrap: wrap;
  padding: spacer(2);

  // @include media-breakpoint-up(sm) {
  //   padding: spacer(3);
  //   align-items: center;
  // }
}
.call2action-event-card__main {
  @include media-breakpoint-down(sm) {
    padding: spacer(3);
  }
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='6' stroke-dasharray='10%2c 25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: $border-radius-lg;
  align-items: center;
  justify-content: center;
  // max-width: 440px;
  position: relative;

  @include media-breakpoint-up(sm) {
    padding: spacer(5);
  }

  @include media-breakpoint-down(sm) {
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='3' stroke-dasharray='10%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}

.call2action-event-card__title {
  @include media-breakpoint-down(sm) {
    font-size: rem(24);
    padding: 0 spacer(3);
  }
}

.call2action-event-card__subtitle {
  line-height: 1.2;

  @include media-breakpoint-down(sm) {
    font-size: rem(18);
  }
}
