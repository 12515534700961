@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button.button--#{$name} {
    --button-background: #{$bg-color};
    --button-background-darker: #{darkenColor($bg-color, 0.2)};
    --button-background-alpha: #{alpha($bg-color, 0.2)};
    --button-color: #{$font-color};
  }
}

.button.button--light-secondary:hover {
  --button-background-darker: var(--bs-primary);
}

.button.button--light-primary {
  --button-background: var(--bs-primary);
  --button-color: var(--bs-light);

  &.button-outline:hover {
    --button-background-darker: var(--bs-light);
    --button-background-alpha: var(--bs-primary);
  }
}
