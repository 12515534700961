.content-block-cta {
  display: flex;
  text-align: center;
}

.map-cta-button {
  transform: translateY(50%);
  margin-top: -1em;
}

.map-cta__figure {
  border-radius: 12px;
  overflow: hidden;
  display: block;
}
