.fr-img-space-wrap {
  margin-top: rem(20px);
  margin-bottom: map-get($spacers, 4);
}

.fr-inner {
  color: var(--bs-primary);
  margin: 5px 0 20px;
  display: inline-block;
  font-size: 14px;
  display: flex;
  justify-content: end;
}
