$statusses: 'success', 'danger', 'info', 'warning';

.alert {
  color: white;
  padding: 1em;
  border-radius: $border-radius-sm;

  @each $status in $statusses {
    &--#{$status} {
      background-color: var(--bs-#{$status});
    }
  }

  ul {
    padding-left: 1em;

    li {
      list-style: disc;
    }
  }
}

.form {
  .alert {
    margin-bottom: spacer(3);
  }
}
