.footer {
  background-color: var(--bs-secondary);
  position: relative;
  //margin-top: 100px;
  //&::before {
  //  background-image: url(/assets/gfx/footer-wavy-top.svg);
  //  content: '';
  //  width: 100%;
  //  height: 200px;
  //  background-repeat: repeat-x;
  //  position: absolute;
  //  background-size: contain;
  //  top: -100px;
  //  z-index: -1;
  //}
  &__sub {
    background-color: var(--bs-light);
    color: var(--bs-primary);
    display: flex;
    font-weight: bold;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__socials {
    display: flex;
    align-items: center;
    app-icon {
      margin-inline-start: 0 !important;
    }
  }
  &__menu {
    &-list__item {
      a {
        color: var(--bs-dark);
        text-decoration: none;
        &:hover {
          color: var(--bs-primary);
        }
      }
    }
  }
}
