.nav-list {
  @include media-breakpoint-up(sm) {
    column-count: 2;
  }

  &__item {
    display: inline-block;
    width: 100%;
    margin-bottom: 3em;

    a {
      text-decoration: none;
      display: inline-block;
      width: 100%;
      padding: 0.2em 0;

      &.is--current {
        text-decoration: underline;
        color: var(--bs-secondary);
      }
    }
  }
}

.topbar__overlay {
  height: calc(100dvh - var(--topbar-height-total));
  display: flex;
  flex-flow: column;
  position: relative;
  z-index: 3;
  background: white;
  animation: topbar-fade-in 0.4s ease-in-out both;

  &__inner {
    height: 100%;
    display: flex;

    // op dekstop is het per kolom
    @include media-breakpoint-down(lg) {
      overflow: auto;
      overflow-x: hidden;
      @include scrollbar();
    }
  }
}

.topbar {
  // weird bootstrap settings fix
  @include media-breakpoint-up(sm) {
    .row,
    .container {
      --bs-gutter-x: 3rem;
    }
  }

  @include media-breakpoint-down(md) {
    .container.topbar__bar__inner {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .row {
      margin-right: calc(-1.5 * var(--bs-gutter-x));
      margin-left: calc(-1.5 * var(--bs-gutter-x));
    }
  }
}

.overlay {
  // weird bootstrap settings fix
  //@include media-breakpoint-down(xl) {
  //  --bs-gutter-x: 0.25rem;

  //  margin-right: calc(-1.5 * var(--bs-gutter-x));
  //  margin-left: calc(-1.5 * var(--bs-gutter-x));

  //  > * {
  //    padding-right: calc(var(--bs-gutter-x) * 1.5);
  //    padding-left: calc(var(--bs-gutter-x) * 1.5);
  //  }
  //}
  //@include media-breakpoint-down(lg) {
  //  --bs-gutter-x: 0.75rem;
  //}

  &__main {
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      padding-bottom: 20px;
      max-height: 100%;
    }

    &__inner {
      @include scrollbar(var(--bs-gray-100));

      @include media-breakpoint-down(lg) {
        padding-top: 60px;
        padding-bottom: 40px;
      }

      @include media-breakpoint-up(lg) {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100%;
        width: 100%;
        padding: 20px 0;
        padding-top: 10vh;
      }
    }
  }

  &__sidebar {
    position: relative;
    z-index: 1;
    display: flex;
    max-height: 100%;

    @include media-breakpoint-down(lg) {
      padding-bottom: 60px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__inner {
      @include scrollbar(white);
      z-index: 2;

      @include media-breakpoint-down(lg) {
        padding-bottom: 40px;
      }

      @include media-breakpoint-up(lg) {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100%;
        width: 100%;
        padding: 20px 0;
        padding-top: 10vh;
      }
    }

    &::before {
      content: '';
      @include abs();
      background-color: var(--bs-secondary);
      z-index: 1;

      @include media-breakpoint-up(lg) {
        width: 100vw;
        height: 100vh;
        bottom: 0;
        top: auto;
      }
    }

    &__wave {
      --bg-wave-ratio: 4;
      position: absolute;
      z-index: 2;
      aspect-ratio: 5;
      top: 0;
      display: block;

      @include media-breakpoint-down(lg) {
        top: 0;
        transform: translateY(-100%);
        left: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 100vh;
        display: block;
        transform-origin: top left;
        transform: translateY(calc(var(--topbar-height-total) * -1)) rotate(90deg) scaleY(-1) translateY(calc(-100% + -10px));
      }
    }
  }
}

.theme-nav-button.is--current {
  --button-background: var(--bs-primary);
  --button-color: white;
  --button-background-darker: color-mix(in srgb, var(--bs-primary), black 20%);

  .chip {
    --chip-background: var(--bs-light);
    --chip-color: var(--bs-primary);
  }
}

.topbar__overlay {
  app-actions-card {
    max-width: 600px;
  }
}

@include media-breakpoint-down(md) {
  .overlay__main__inner,
  .overlay__sidebar__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
