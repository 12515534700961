app-bg-wave {
  display: contents;
}

.bg-wave {
  --offset-correction: 60px;
  width: calc(100%);
  object-fit: cover;
  aspect-ratio: var(--bg-wave-ratio, 8);
  height: auto;
  display: block;

  &.wave--correct-offset {
    --offset-correction: 10cqw;
  }

  margin-top: calc(var(--offset-correction) * -1);
  margin-bottom: 0;

  &--flipped svg {
    transform: rotate(-180deg);

    margin-bottom: calc(var(--offset-correction) * -1);
    margin-top: 0;
  }
}

#WaveAnim {
  animation-play-state: paused;
}
