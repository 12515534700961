.event-date {
  text-align: center;
  display: flex;
  flex-flow: column;

  .event-date--end__label {
    margin-top: -#{spacer(3)};
    padding-bottom: spacer(1);
    padding-top: spacer(1);
  }

  &__day {
    color: var(--bs-secondary);
    font-size: map-get($font-sizes, 2);
    font-family: $font-family-serif;
    line-height: 0.9;
    margin-bottom: 0;
    font-weight: 500;
  }

  &__end-label__label {
    margin-top: -#{spacer(3)};
  }

  &__month {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-family-sans-serif;
    color: var(--bs-primary);
    line-height: 1;
  }

  &__year {
    font-family: $font-family-sans-serif;
    color: var(--bs-primary);
    text-transform: uppercase;
  }

  &--jumbo {
    .event-date__day {
      font-size: map-get($font-sizes, 1);
    }
  }
  &--sm {
    .event-date__day {
      font-size: map-get($font-sizes, 3);
    }
    .event-date__month {
      font-size: 1em;
    }
  }

  &--chip {
    background-color: var(--bs-light);
    border-radius: $border-radius-lg;
    display: inline-flex;
    padding: spacer(3) spacer(4);
  }
}
