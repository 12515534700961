.mat-form-field-appearance-fill.mat-form-field-search {
  .mat-form-field-label-wrapper {
    --label-wrapper-top-offset: 0.54375em;
    top: calc(var(--label-wrapper-top-offset) * -1);
    padding-top: var(--label-wrapper-top-offset);
  }

  .mat-form-field-flex {
    background: var(--bs-gray-200);
    border: none;
    // padding: 1.25em 0.95em;
    padding: 1.25em 0.95em 0.85em 0.95em;
    border-radius: 60px !important;
  }
  .mat-form-field-infix {
    padding: 0;
    border: none;
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-0.69375em) scale(0.75);
  }

  input.mat-input-element {
    margin-top: 0.3em;
  }

  .mat-form-field-suffix {
    transform: translate(0px, -0.2em);
  }
}
